import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { ActionsButtons, Card, Page, Preloader } from "lib/common";
import { CardBody, CardHeader } from "shards-react";
import { FiRepeat } from "react-icons/fi";
import { bind } from "decko";
import { checkDocumentsFetching } from "selectors/documents";
import {
  checkMatchesFetching,
  getGlobalStats,
  getSelectedBusinessData
} from "selectors/businesses";
import { checkModalContentShown } from "selectors/ui";
import {
  checkTransactionsFetching,
  getTransactionsData,
  getTransactionsStats,
  getTransactionsTags
} from "selectors/transactions";
import { checkUsersFetching, getActiveOrganization, getAllUsersData } from "selectors/organizations";
import { connect } from "react-redux";
import { getAccountsData } from "selectors/accounts";
import { getEnvVars } from "selectors/envVars";
import { getTextsData } from "selectors/texts";
import { getUserData, getUserRestrictions, getUserRole } from "selectors/user";
import { v4 as uuid } from "uuid";
import ChangeStatusSelector from "lib/common/ChangeStatusSelector";
import CommentsWindow from "lib/windows/CommentsWindow";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import PageActionsRow from "lib/common/PageActionsRow";
import PageHeader from "lib/common/PageHeader";
import React, { PureComponent } from "react";
import StatusFilter from "lib/common/StatusFilter";
import TasksBlock from "nlib/common/TasksBlock";
import TransactionsActions from "actions/TransactionsActions";
import TransactionsFilter from "lib/common/TransactionsFilter";
import TransactionsStatuses from "lib/pages/TransactionsPage/TransactionsStatuses";
import TransactionsTable from "./lib/TransactionsTable";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import moment from "moment";

const mapStateToProps = (state) => ({
  fetchingData: checkTransactionsFetching(state)
    || checkUsersFetching(state)
    || checkDocumentsFetching(state)
    || checkMatchesFetching(state),
  modalContentShown: checkModalContentShown(state),
  selectedBusinessData: getSelectedBusinessData(state),
  usersData: getAllUsersData(state),
  userData: getUserData(state),
  userRole: getUserRole(state),
  userRestrictions: getUserRestrictions(state),
  envVars: getEnvVars(state),
  activeOrganization: getActiveOrganization(state),
  transactionsStats: getTransactionsStats(state),
  transactionsTags: getTransactionsTags(state),
  transactionsData: getTransactionsData(state),
  accountsData: getAccountsData(state),
  globalStats: getGlobalStats(state),
  textsData: getTextsData(state)
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (...args) => dispatch(UiActions.showModal(...args)),
  fetchTransactionsList: (...args) => dispatch(TransactionsActions.fetchTransactionsList(...args)),
  fetchTransactionsTagsList: (...args) => dispatch(TransactionsActions.fetchTransactionsTagsList(...args)),
  bulkTransactionsUpdate: (...args) => dispatch(TransactionsActions.bulkTransactionsUpdate(...args))
});

@connect(mapStateToProps, mapDispatchToProps)
class TransactionsPage extends PureComponent {
  dataListUpdateTimeoutId = null;

  willBeUnmounted = false;

  constructor(props) {
    super(props);

    let {
      envVars: {
        editItem,
        status,
        month,
        fromDate = month ? moment(month).startOf("month").format(Constants.DATETIME_FORMATS.API_DATE) : undefined,
        toDate = month ? moment(month).endOf("month").format(Constants.DATETIME_FORMATS.API_DATE) : undefined,
        text,
        type,
        accountId
      }
    } = this.props;

    const [editableTransactionId, editableTransactionTab] = editItem ? editItem.split(".") : [null, null];

    const validDateRange = moment(fromDate).isValid() && moment(toDate).isValid();

    const filters = {
      fromDate: validDateRange ? fromDate : undefined,
      toDate: validDateRange ? toDate : undefined,
      month,
      text,
      type,
      accountId: Utils.checkIsMongoDbId(accountId) ? accountId : undefined
    };

    if (editableTransactionId && !filters.text) filters.text = editableTransactionId;

    status = status && (TransactionsStatuses.getCzStatusData(status) ? status : undefined);

    this.state = {
      filters,
      test: uuid(), // Just random UUID on creation
      statusFilter: status === undefined ? null : status,
      sortings: null,
      selectedItems: [],
      tablePage: 0,
      tablePages: 0,
      tablePageSize: Constants.TABLE_PAGE_SIZE,
      askClientByEmailWindowOpened: false,
      commentsTransactionId: editableTransactionTab === Constants.COMMENTS && editableTransactionId
    };
  }

  getDataRequestOffset(leaveCurrent = false) {
    const { tablePage, tablePages, tablePageSize } = this.state;

    if (!leaveCurrent && tablePage === tablePages - 1) return (tablePage > 0 ? tablePage - 1 : 0) * tablePageSize;

    return tablePage * tablePageSize;
  }

  getTransactionDataById(transactionId) {
    return this.props.transactionsData.find(({ id }) => id === transactionId);
  }

  getStatusesList() {
    return Object.values(TransactionsStatuses.getCzStatusData());
  }

  @bind
  async initTransactionsPage(dataOffset = 0, clearList = true, backgroundUpdate = false) {
    const { fetchTransactionsList, fetchTransactionsTagsList } = this.props;

    const {
      filters: {
        month: selectedMonth,
        fromDate = selectedMonth ? moment(selectedMonth).startOf("month").format(Constants.DATETIME_FORMATS.API_DATE) : undefined,
        toDate = selectedMonth ? moment(selectedMonth).endOf("month").format(Constants.DATETIME_FORMATS.API_DATE) : undefined,
        ...filters
      },
      sortings,
      statusFilter,
      tablePageSize
    } = this.state;

    clearTimeout(this.dataListUpdateTimeoutId);
    await fetchTransactionsList(
      clearList,
      backgroundUpdate,
      statusFilter,
      {
        ...filters,
        fromDate,
        toDate
      },
      sortings,
      dataOffset,
      tablePageSize
    );
    await fetchTransactionsTagsList();
    if (!this.willBeUnmounted) {
      this.dataListUpdateTimeoutId = setTimeout(
        () => { this.initTransactionsPage(dataOffset, false, true); },
        Constants.DATA_LIST_UPDATE_INTERVAL
      );
    }
  }

  resetTransactionsPage(clearList = true) {
    this.initTransactionsPage(0, clearList);
    this.setState({ tablePage: 0 });
  }

  bulkUpdateSelectedTransactions(data = {}) {
    const { STATUSES: { TO_REPORT } } = DataConstants;

    const { transactionsData, bulkTransactionsUpdate } = this.props;

    const transactionIds = this.state.selectedItems.filter((id) => {
      const transactionData = this.getTransactionDataById(id);

      return transactionData && transactionData.status !== TO_REPORT && transactionData.status !== data.status
        && TransactionsStatuses.getCzStatusData(data.status).canBeAssigned;
    });

    this.setState({ selectedItems: [] });

    if (transactionIds.length) {
      bulkTransactionsUpdate({ ids: transactionIds, data })
        .then((result) => {
          const { successCount: updatedTransactionsCount } = result || {};

          if (updatedTransactionsCount) {
            const statusChangedCount = data.status || (data.tags && !data.category) || !Object.values(data).length
              ? updatedTransactionsCount
              : 0;

            const offset = this.getDataRequestOffset(transactionsData.length - statusChangedCount > 0);

            this.initTransactionsPage(offset, false);
          }
        });
    }
  }

  componentDidMount() {
    this.resetTransactionsPage();
  }

  componentWillUnmount() {
    this.willBeUnmounted = true;
    clearTimeout(this.dataListUpdateTimeoutId);
  }

  @bind
  handleStatusFilterChange(statusFilter) {
    const { envVars, history } = this.props;

    this.setState({ statusFilter }, () => {
      history.replace(`?${Utils.objectToQueryString({ ...envVars, status: statusFilter })}`);
      this.resetTransactionsPage(false);
    });
  }

  @bind
  handleTransactionsFilterChange(cb) {
    const { envVars: { status }, history } = this.props;

    const { filters: prevFilters } = this.state;

    const filters = cb(prevFilters);

    this.setState({ filters }, () => {
      history.replace(`?${Utils.objectToQueryString({ ...filters, status })}`);
      this.resetTransactionsPage(false);
    });
  }

  @bind
  handleTransactionsUnselect() {
    this.setState({ selectedItems: [] });
  }

  @bind
  handleTableFetchData({ page: tablePage, pages: tablePages, pageSize: tablePageSize, sorted }) {
    const [sortings = null] = sorted || [];

    const sortingsChanged = !Utils.checkDeepEquality(sortings, this.state.sortings);

    if (tablePage === tablePages) --tablePage;
    if (tablePage !== this.state.tablePage || tablePageSize !== this.state.tablePageSize || sortingsChanged) {
      this.setState({ tablePage, tablePageSize, sortings }, () => {
        this.initTransactionsPage(this.getDataRequestOffset(true), false);
      });
    }
    if (tablePages !== this.state.tablePages) this.setState({ tablePages });
  }

  @bind
  handleTableItemsSelect(selectedItems) {
    this.setState({ selectedItems });
  }

  @bind
  handleTransactionCommentsOpen(transactionId) {
    const { envVars, history } = this.props;

    history.replace(
      `?${Utils.objectToQueryString({ ...envVars, editItem: `${transactionId}.${Constants.COMMENTS}` })}`
    );
    this.setState({ commentsTransactionId: transactionId });
  }

  @bind
  handleCommentsWindowClose({ needReaction, transactionId } = {}) {
    const { envVars, history } = this.props;

    history.replace(
      `?${Utils.objectToQueryString({ ...envVars, editItem: null })}`
    );

    this.setState({ commentsTransactionId: null }, () => {
      if (needReaction) {
        this.setState(({ selectedItems }) => ({ selectedItems: [...selectedItems, transactionId] }));
      }
    });
  }

  @bind
  handleChangeStatusSelectorChange(status) {
    this.bulkUpdateSelectedTransactions({ status });
  }

  renderNoDataContent() {
    const {
      textsData: { uiTexts },
      fetchingData
    } = this.props;

    const { statusFilter } = this.state;

    const { labelLangId: statusLabelLangId } = (statusFilter && TransactionsStatuses.getCzStatusData(statusFilter)) || {};

    const statusLabel = statusLabelLangId && uiTexts[statusLabelLangId].toLowerCase();

    if (fetchingData) return <Preloader />;

    return (
      <div className={CommonCss.noDataContent}>
        <div>
          <div><FiRepeat /></div>
          <div>
            {statusLabel
              ? Utils.replaceTextVars(uiTexts.noStatusTransactions, { status: statusLabel })
              : uiTexts.noTransactions}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { STATUSES: { TO_REVIEW } } = DataConstants;

    const {
      textsData: { uiTexts },
      selectedBusinessData: {
        name: businessName,
        extraData: { transactionsLastSyncedAt } = {}
      },
      transactionsData,
      transactionsStats,
      userRestrictions,
      fetchingData
    } = this.props;

    const {
      statusFilter,
      filters,
      sortings,
      selectedItems,
      tablePage,
      tablePageSize,
      commentsTransactionId
    } = this.state;

    const subTitle = transactionsLastSyncedAt
      ? Utils.replaceTextVars(
        uiTexts.lastSyncDate,
        { date: moment.duration(moment.utc(transactionsLastSyncedAt).diff(moment.utc())).humanize(true) }
      )
      : businessName;

    const totalDataCount = transactionsStats.current || 0;

    const commentsWindowTransactionData = Utils.arrayFindById(transactionsData, commentsTransactionId);

    return (
      <Page className={Css.transactionsPage}>
        <TasksBlock className={CommonCss.tasksBlock} />
        <PageHeader subTitle={subTitle} />
        <Card>
          <CardHeader className={Css.cardHeader}>
            <div>
              <StatusFilter
                statusesList={this.getStatusesList()}
                stats={transactionsStats}
                value={statusFilter}
                disabled={fetchingData}
                onChange={this.handleStatusFilterChange} />
            </div>
          </CardHeader>
          <CardBody className={Css.cardBody}>
            <PageActionsRow
              data-navbar
              sticky={selectedItems.length || Object.values(filters).filter(Boolean).length}>
              <TransactionsFilter
                disabled={fetchingData}
                filters={filters}
                onChange={this.handleTransactionsFilterChange} />
              {!!(transactionsData.length && selectedItems.length) && (
                <>
                  <div><span>{uiTexts.selected}: </span><b>{selectedItems.length}</b></div>
                  <ChangeStatusSelector
                    statusesList={this.getStatusesList()}
                    excludedStatuses={[
                      statusFilter,
                      userRestrictions.transactionsUpdate && TO_REVIEW
                    ]}
                    disabled={fetchingData}
                    onChange={this.handleChangeStatusSelectorChange} />

                  <ActionsButtons
                    className={Css.actionsButtons}
                    onUnselect={this.handleTransactionsUnselect} />
                </>
              )}
            </PageActionsRow>
            {transactionsData.length ? (
              <TransactionsTable
                disabled={fetchingData}
                data={transactionsData}
                page={tablePage}
                pageSize={tablePageSize}
                sortings={sortings}
                totalDataCount={totalDataCount}
                selectedItems={selectedItems}
                onFetchData={this.handleTableFetchData}
                onItemsSelect={this.handleTableItemsSelect}
                onTransactionCommentsOpen={this.handleTransactionCommentsOpen} />
            ) : this.renderNoDataContent()}
          </CardBody>
          {commentsWindowTransactionData && (
            <CommentsWindow
              transaction={commentsWindowTransactionData}
              onClose={this.handleCommentsWindowClose} />
          )}
        </Card>
      </Page>
    );
  }
}

export default TransactionsPage;
